export {DisneyOneidAuthComponent} from './DisneyOneidAuthComponent';
export type {DisneyOneidAuthComponentDefinition} from './DisneyOneidAuthComponent';
export {ComponentDefinition} from './DisneyOneidAuthDefinition';
export type {SchemaType} from './DisneyOneidAuthDefinition';
export {DisneyOneidAuthPreviewComponent} from './DisneyOneidAuthPreviewComponent';

import {Registry} from '@backstage-components/base';
import type {
  GuestAuthSuccessEvent,
  GuestExternalAuthSuccessEventName,
} from '@backstage-components/base';
import {DisneyOneidAuthModule} from './DisneyOneidAuthModule';
import {ComponentDefinition} from './DisneyOneidAuthDefinition';
Registry.register(ComponentDefinition, DisneyOneidAuthModule);

declare global {
  // Extends `#addEventListener` and `#removeEventListener` type definition to
  // include an overload for the `GuestExternalAuthSuccessEventName` custom
  // event
  interface HTMLElement {
    addEventListener(
      type: GuestExternalAuthSuccessEventName,
      listener: (this: HTMLElement, ev: GuestAuthSuccessEvent) => void,
      options?: boolean | AddEventListenerOptions
    ): void;
    removeEventListener(
      type: GuestExternalAuthSuccessEventName,
      listener: (this: HTMLElement, ev: GuestAuthSuccessEvent) => void,
      options?: boolean | EventListenerOptions
    ): void;
  }
}
