import {ModuleNode} from '@backstage-components/base';
import {FC} from 'react';
import {SchemaType} from './DisneyOneidAuthDefinition';
import {Alert, AlertIcon} from '@chakra-ui/react';

export type DisneyOneidAuthProps = SchemaType;

export const DisneyOneidAuthPreviewComponent: FC<
  ModuleNode<'DisneyOneidAuth', DisneyOneidAuthProps>
> = () => {
  return (
    <Alert status="info">
      <AlertIcon />
      UI for this component is provided by Disney OneID, a flow node is required
      to initialize and launch the Disney OneID login flow.
    </Alert>
  );
};
