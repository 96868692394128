import {Module, NextModule} from '@backstage-components/base';
import React from 'react';
import {
  DisneyOneidAuthComponent,
  DisneyOneidAuthComponentDefinition,
} from './DisneyOneidAuthComponent';
import {ComponentDefinition} from './DisneyOneidAuthDefinition';

type Kind = DisneyOneidAuthComponentDefinition['component'];

type Props = DisneyOneidAuthComponentDefinition['props'];

export const DisneyOneidAuthModule: Module<Kind, Props> = {
  isContent(value): value is DisneyOneidAuthComponentDefinition {
    return (
      value.component === ComponentDefinition.reactName &&
      value.cid === ComponentDefinition.id
    );
  },
  isLayout(value): value is DisneyOneidAuthComponentDefinition {
    return false;
  },
  tryRender(definition) {
    if (DisneyOneidAuthModule.isContent(definition)) {
      return React.createElement(DisneyOneidAuthComponent, definition);
    } else {
      return NextModule;
    }
  },
};
